/**
 * Containers
 */
%fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%container-cols {
    display: table;
    width: 100%;
}

%container-col {
    display: table-cell;
}

/**
 * Inners
 */
%page-inner {
    margin: 0 10px;

    @media #{$mq-medium-screen} {
        max-width: 700px;
        margin: 0 auto;
    }

    @media #{$mq-large-screen} {
        max-width: 800px;
    }
}

/**
 * Elements
 */
%line {
    display: block;
    width: 100%;
    margin: 20px 0;
    border: 0;
    background-repeat: repeat-x;
    background-position: top left;

    &.noMargin {
        margin: 0;
    }
}

%rainbow-gradient {
    background: linear-gradient(
        to right,
        #00a0db 0%,
        #362983 20%,
        #e4007d 40%,
        #e31f21 60%,
        #ffe900 80%,
        #009447 100%
    );
}

%rainbow-gradient-diagonal {
    background: linear-gradient(
        45deg,
        #00a0db 0%,
        #362983 20%,
        #e4007d 40%,
        #e31f21 60%,
        #ffe900 80%,
        #009447 100%
    );
}

/**
 * Controls
 */
%reset-button {
    display: inline-block;
    position: relative;
    padding: 0;
    border: 0;
    background: transparent;
    color: inherit;
    font-family: $font-text;
    cursor: pointer;
    appearance: none;
}

%reset-input {
    padding: 0;
    border: 0;
    background: transparent;
    font-family: $font-text;
    cursor: pointer;
    appearance: none;
}

%button {
    @extend %reset-button;
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: visible;
    transition: border-color 0.2s ease-out;
    border: solid 5px rgba(0, 0, 0, 0.2);
    border-top-color: rgba(255, 255, 255, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.4);

    &:active {
        border-top-color: rgba(0, 0, 0, 0.4);
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }

    &:disabled {
        border-top-color: rgba(0, 0, 0, 0.4);
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }
}
