@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    .inner {
        transition: opacity 0.2s ease-out;
    }

    &.fullscreen {
        @extend %fullscreen;

        .inner {
            @extend %fullscreen;
        }
    }
}
