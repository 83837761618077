@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .title {
        font-size: $font-size-bigger;
        font-weight: bold;
    }
}
