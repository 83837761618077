@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %fullscreen;
    overflow: hidden;
    background: url('../../img/background.gif') repeat top left;
    color: $color-white;

    .robot {
        position: absolute;
        z-index: 1;
        top: 5%;
        left: 0;
        width: 150px;
        height: 156px;
        transform: translate(-31%, 0);
        transform-origin: 0 0;
        background: url('../../img/robot.png') no-repeat center center;
        background-size: contain;
    }

    .inner {
        @extend %fullscreen;
        display: flex;
        position: relative;
        z-index: 2;
        flex-direction: column;
        padding: 10px;
    }

    .titleContainer,
    .buttonContainer,
    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .creditsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .titleContainer {
        flex-grow: 1;
    }

    .buttonContainer {
        flex-basis: 60px;
        text-align: center;
    }

    .textContainer {
        flex-grow: 1;
        text-align: center;
    }

    .title {
        display: block;
        width: 100%;
        height: 0;
        margin-bottom: 60px;
        padding-bottom: 27%;
        background: url('../../img/title.gif') no-repeat center center;
        background-size: contain;
        color: $color-white;
        font-size: $font-size-big;
        font-weight: bold;
        text-align: center;
        text-indent: -9999px;
    }

    .text {
        margin: 0 auto;
        margin-top: 20px;
        font-size: $font-size-normal;
        line-height: 1.3;

        em {
            font-style: italic;
        }
    }

    .button {
        @extend %button;
        display: flex;
        width: 200px;
        height: 60px;
        min-height: 60px;
        margin: 0 auto;
        animation: color-change infinite 5s;
        animation-timing-function: steps(10, start);
        background: $color-green;
    }

    .credits {
        margin-top: 20px;
        color: $color-white;
    }

    @media #{$mq-small-screen} {
        .robot {
            width: 300px;
            height: 311px;
        }
    }

    @media #{$mq-medium-screen} {
        .titleContainer,
        .buttonContainer,
        .textContainer {
            width: 800px;
            max-width: 800px;
            margin: 0 auto;
        }

        .buttonContainer {
            flex-basis: 70px;
        }

        .text {
            font-size: $font-size-medium-small;
        }

        .button {
            height: 70px;
            min-height: 70px;
            border-width: 10px;
        }
    }

    @media #{$mq-large-screen} {
        .inner {
            padding: 20px;
        }

        .titleContainer,
        .buttonContainer,
        .textContainer {
            width: 900px;
            max-width: 900px;
            margin: 0 auto;
        }

        .buttonContainer {
            flex-basis: 90px;
        }

        .text {
            font-size: $font-size-medium;
        }
    }

    @media #{$mq-x-large-screen} {
        .robot {
            width: 600px;
            height: 622px;
        }

        .titleContainer,
        .buttonContainer,
        .textContainer {
            width: 1200px;
            max-width: 1200px;
            margin: 0 auto;
        }

        .buttonContainer {
            flex-basis: 120px;
        }

        .text {
            font-size: $font-size-medium-big;
        }

        .button {
            transform: scale(2);
            transform-origin: 50% 50%;
        }
    }
}

@keyframes color-change {
    0% {
        background: $color-green;
    }

    20% {
        background: $color-yellow;
    }

    40% {
        background: $color-red;
    }

    60% {
        background: $color-purple;
    }

    80% {
        background: $color-pink;
    }

    100% {
        background: $color-green;
    }
}
