
@font-face {
    font-family: 'Aztek';
    src: url('../fonts/aztek/aztek.eot?') format('eot'),
        url('../fonts/aztek/aztek.woff2') format('woff2'),
        url('../fonts/aztek/aztek.woff') format('woff'),
        url('../fonts/aztek/aztek.ttf')  format('truetype'),
        url('../fonts/aztek/aztek.svg#Aztek') format('svg');
}

@font-face {
    font-family: 'Garage Gothic';
    font-weight: bold;
    src: url('../fonts/garage-gothic/GarageGothic-Bold.eot?') format('eot'),
        url('../fonts/garage-gothic/GarageGothic-Bold.woff2') format('woff2'),
        url('../fonts/garage-gothic/GarageGothic-Bold.woff') format('woff'),
        url('../fonts/garage-gothic/GarageGothic-Bold.otf') format('opentype'),
        url('../fonts/garage-gothic/GarageGothic-Bold.ttf') format('truetype'),
        url('../fonts/garage-gothic/GarageGothic-Bold.svg#GarageGothic-Bold') format('svg');
}

@font-face {
    font-family: 'Brandon Grotesque';
    font-weight: bold;
    src: url('../fonts/brandon/Brandon_bld.woff2') format('woff2'),
        url('../fonts/brandon/Brandon_bld.woff') format('woff'),
        url('../fonts/brandon/Brandon_bld.otf') format('opentype'),
        url('../fonts/brandon/Brandon_bld.ttf') format('truetype'),
        url('../fonts/brandon/Brandon_bld.svg#Brandon_bld') format('svg');
}

@font-face {
    font-family: 'Brandon Grotesque';
    font-weight: 500;
    src: url('../fonts/brandon/Brandon_med.eot?') format('eot'),
        url('../fonts/brandon/Brandon_med.woff2') format('woff2'),
        url('../fonts/brandon/Brandon_med.woff') format('woff'),
        url('../fonts/brandon/Brandon_med.otf') format('opentype'),
        url('../fonts/brandon/Brandon_med.ttf') format('truetype'),
        url('../fonts/brandon/Brandon_med.svg#Brandon_med') format('svg');
}
