@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin line-with-icons-size($width, $height, $scale: 1) {
    height: $height * $scale;
    background-size: ($width * $scale) ($height * $scale);
}

@mixin line-with-icons($icon, $width, $height, $scale: 1) {
    @include line-with-icons-size($width, $height, $scale);
    background-image: url('#{$icon}');
    background-repeat: round;
}
