@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: inline-block;
    font-family: $font-text;
    font-size: $font-size-smaller;
    font-weight: normal;

    .icon,
    .label {
        display: inline-block;
        vertical-align: middle;
    }

    .icon {
        margin-right: 10px;
    }

    strong,
    a {
        font-weight: bold;
    }

    a {
        text-decoration: underline;
    }
}
