/**
 * Commons
 */
@import 'commons/variables';
@import 'commons/placeholders';
@import 'commons/mixins';
@import 'commons/fonts';
@import 'commons/reset';
@import 'commons/general';

html,
body {
    min-height: 100vh;
}

body {
    background: $color-background;
    color: $color-text;
    font-family: $font-text;
}

/**
 * Vendors
 */
@import 'vendor/nouislider';
